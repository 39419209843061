// Press "ESC"

$(document).keyup(function (e) {
    if (e.keyCode == 27) {
        $(".header-mainnav, .bookmarks-toggle, .search-wrapper, .search-close").removeClass("search-active");
        $(".overlay").removeClass("active menu-active search-active bookmarks-active open-position-active off-canvas-active");
        $(".hamburger").removeClass("is-active");
        $("#off-canvas").removeClass("active");
        $("#off-canvas").removeClass("off-canvas-active");
        $(".filter-select-wrapper ul").removeClass("open");
        $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
        $("#main-nav ul li.has-subnav").removeClass("active");
        $("#main-nav ul li").removeClass("active");
        $('.active-hover').removeClass('active-c');
    }
});
$(".overlay").click(function () {
    $(".overlay").removeClass("active open-position-active off-canvas-active");
    $(".hamburger").removeClass("is-active");
    $("#off-canvas").removeClass("active");
    $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
});

// OffCanvas

$(".off-canvas-toggle").click(function () {
    $(".hamburger").toggleClass("is-active");
    $("#main-wrapper, .overlay").toggleClass("off-canvas-active");
    $("#off-canvas").toggleClass("active");
    $(".search-wrapper, .search-close, .bookmarks-toggle, .overlay").removeClass("search-active");
    $("body").toggleClass("no-scroll-off-canvas");
    var offCanvasHeight = $(".off-canvas-wrapper").height();
    $(".off-canvas-wrapper").css("height", offCanvasHeight);
});

$('.acc-menu > ul > li:has(ul)').addClass("has-subnav");
$('.acc-menu > ul > li > a').click(function() {
    $(this).closest('li').toggleClass('open') ;
});

// Select Desktop

$( ".select-desktop li" ).click(function() {
    $(this).closest(".select-desktop").find("li:first-child").toggleClass( 'active' );
});



$("#main-nav ul li").click(function(){
    $(this).toggleClass("active");
});

// Slider
// ------- Startseite
$('.slider-startseite').slick({
    dots: true,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"></button>',
    nextArrow: '<button class="slick-next" aria-label="Next" type="button"></button>',
    adaptiveHeight: true,
    responsive: [
        {
            breakpoint: 800,
            settings: {
                arrows: false

            }
        }
    ]
});

// ScrollAnimation
if(document.querySelector('.ivict-animation')) {
    (function() {
        var elements;
        var windowHeight;
    
        function init() {
            elements = document.querySelectorAll('.ivict-animation');
            windowHeight = window.innerHeight;
            windowHeightHalf = windowHeight / 1.5;
        }
    
        function checkPosition() {
            for (var i = 0; i < elements.length; i++) {
                var element = elements[i];
                var positionFromTop = elements[i].getBoundingClientRect().top;
    
                if (positionFromTop - windowHeight <= -windowHeightHalf) {
                    element.classList.add('active');
                }
            }
        }
    
        window.addEventListener('scroll', checkPosition);
        window.addEventListener('resize', init);
    
        init();
        checkPosition();
    })();
}

$(".info-banner span").each(function(){
    var parts = $(this).text().trim().toString().split(".");
    $(this).text(parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "."));
});

$(window).resize(function(){
    $(".primary-box .grid-wrapper div div").css("height", "");
    if($( window ).width() < "640"){
        $(".primary-box .grid-wrapper div div").css("height", "");
    } else {
        var maxHeight = -1;
        $(".primary-box .grid-wrapper div div").each(function() {
            maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
        });
        $(".primary-box .grid-wrapper div div").css("height", maxHeight);
    }
});

window.dispatchEvent(new Event('resize'));




